import $ from 'jquery';

import stickyMenu from './components/stickyMenu';
import goToTop from './components/goToTop';
import breadcrumbs from './components/breadcrumbs';
import customSelect from './components/select-woo';
import scrollSpy from './components/scrollSpy';
import editoForm from './components/editoForm';

window.$ = $;
window.jQuery = $;

stickyMenu.init();
goToTop.init();
breadcrumbs.init();
customSelect.init();
scrollSpy.init();
editoForm.init();
