const editoForm = (function () {
    function _init() {
        if (document.getElementById('edito_form_presentation')) {
            document.addEventListener('DOMContentLoaded', () => {
                const textarea = document.getElementById(
                    'edito_form_presentation'
                );
                textarea.addEventListener('keypress', updateCharacterCount);
                textarea.addEventListener('keyup', updateCharacterCount);
                updateCharacterCount();
            });
        }
        if (
            document.querySelector('.zipcode') &&
            document.querySelector('.city')
        ) {
            fillCity();
        }
        const elements = document.querySelectorAll('.phone');
        elements.forEach(function (element) {
            formatPhoneNumber(element);
        });
    }
    function updateCharacterCount() {
        const textarea = document.getElementById('edito_form_presentation');
        const charCounter = document.querySelector('.char-counter');
        const maxLength = textarea.getAttribute('maxlength');
        const maxLengthElement = document.querySelector('.max-length');
        const currentLength = textarea.value.length;
        const remaining = maxLength - currentLength;

        charCounter.textContent = `${remaining}`;
        maxLengthElement.textContent = `/ ${maxLength}`;
    }

    function fillCity() {
        const citySelect = document.querySelector('.city');
        document.addEventListener('DOMContentLoaded', () => {
            const zipcodeInput = document.querySelector('.zipcode');
            const citySelectLabel = citySelect.dataset.label;
            const storedCities = JSON.parse(localStorage.getItem('cities'));
            const selectedCity = localStorage.getItem('selectedCity');
            if (zipcodeInput.value && !citySelect.hasAttribute('data-city')) {
                localStorage.removeItem('cities');
                localStorage.removeItem('selectedCity');
            }
            if (storedCities && selectedCity) {
                populateCityField(citySelect, storedCities, citySelectLabel);
                citySelect.value = selectedCity;
            }
            zipcodeInput.addEventListener('input', async event => {
                const zipcode = event.target.value;
                if (/^\d{5}$/.test(zipcode)) {
                    try {
                        const response = await fetch(
                            `https://geo.api.gouv.fr/communes?codePostal=${zipcode}`
                        );
                        const cities = await response.json();

                        populateCityField(citySelect, cities, citySelectLabel);
                    } catch (error) {
                        console.error('Error fetching city data:', error);
                    }
                } else {
                    citySelect.innerHTML =
                        '<option value="">' + citySelectLabel + '</option>';
                    citySelect.disabled = true;
                }
            });

            zipcodeInput.addEventListener('keypress', event => {
                const charCode = event.which ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57) {
                    event.preventDefault();
                }
            });
        });
        const form = document.querySelector('form');
        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.addEventListener('click', () => {
            const selectedCity = citySelect.value;
            localStorage.setItem('selectedCity', selectedCity);
        });
    }
    function populateCityField(citySelect, cities, citySelectLabel) {
        citySelect.innerHTML =
            '<option value="">' + citySelectLabel + '</option>';
        cities.forEach(city => {
            const option = document.createElement('option');
            option.value = city.nom;
            option.textContent = city.nom;
            citySelect.appendChild(option);
            if (document.querySelector('.country')) {
                populateCountryField(city.codeDepartement);
            }
        });
        localStorage.setItem('cities', JSON.stringify(cities));
        citySelect.disabled = false;
    }
    function populateCountryField(codeDepartement) {
        const countryField = document.querySelector('.country');
        const nextElement = countryField.nextElementSibling;
        const customerCountryField =
            document.querySelector('.customer-country');
        if (countryField) {
            countryField.value = 'France';
            if (nextElement) {
                nextElement.value = 'FR';
            }
        }
        if (overseasDepartments && overseasDepartments[codeDepartement]) {
            const countryCode = overseasDepartments[codeDepartement];
            const contryName = Translator.trans(countryCode, {}, 'messages');
            countryField.value = contryName;
            if (customerCountryField) {
                customerCountryField.value = contryName;
            }
            if (nextElement) {
                nextElement.value = countryCode;
            }
        }
    }

    function formatPhoneNumber(element) {
        document.addEventListener('DOMContentLoaded', () => {
            element.addEventListener('keypress', event => {
                const charCode = event.which ? event.which : event.keyCode;
                if (charCode < 48 || charCode > 57) {
                    event.preventDefault();
                } else {
                    let phoneNumber = event.target.value.replace(/\D/g, '');
                    phoneNumber = phoneNumber.slice(0, 10);
                    let formattedPhoneNumber = '';

                    for (let i = 0; i < phoneNumber.length; i++) {
                        if (i > 0 && i % 2 === 0) {
                            formattedPhoneNumber += ' ';
                        }
                        formattedPhoneNumber += phoneNumber[i];
                    }
                    element.value = formattedPhoneNumber;
                }
            });
        });
    }
    return { init: _init };
})();
export default editoForm;
