import $ from 'jquery';
import * as bootstrap from 'bootstrap';

const scrollSpy = (function () {
    function _init() {
        const $element = $('#scrollspy');

        if ($element.length) {
            const $elementSource = $element.next('.wysiwyg');
            const $elementSourceList = $('.js-summary-list-data');
            const anchorList = $('h2', $elementSource).not(
                '.accordion-item h2'
            );
            const $activeAnchor = $('.js-summary-active');
            let $activeAnchorText;

            if (anchorList.length > 0) {
                $('.scrollspy').removeClass('visually-hidden');
                $('.scrollspy').attr('aria-hidden', 'false');
            }

            if ($(window).width() < 768) {
                $('.js-dropdown-toggle', $element).attr(
                    'aria-expanded',
                    'false'
                );
                $elementSourceList.removeClass('show');
            }

            $($elementSourceList, $element).html('');

            anchorList.each(function (index) {
                $(this).attr('tabindex', '-1');
                $(this).attr('id', 'scrollspy-anchor' + index);
                $(this).attr('class', 'scrollspy-anchor');

                if (index === 0) {
                    $activeAnchorText = $(this).text();
                }

                const link = $(
                    '<li><a class="dropdown-item" href="#scrollspy-anchor' +
                        index +
                        '">' +
                        $(this).text() +
                        '</a></li>'
                );
                $($elementSourceList, $element).append(link);
            });

            $activeAnchor.text($activeAnchorText);

            // eslint-disable-next-line
            const scrollSpy = new bootstrap.ScrollSpy(document.body, {
                target: '#scrollspy'
            });

            const firstScrollSpyEl = document.querySelector(
                '[data-bs-spy="scroll"]'
            );
            firstScrollSpyEl.addEventListener('activate.bs.scrollspy', el => {
                $activeAnchorText = el.relatedTarget.innerText;
                $activeAnchor.text($activeAnchorText);
            });

            let stickyTop =
                $(window).width() > 767
                    ? $element.offset().top - 100
                    : $element.offset().top - 150;
            let position = $(window).scrollTop();
            $(window).on('scroll load', function () {
                if ($(window).width() < 768) {
                    stickyTop = $element.offset().top - 150;
                }

                if ($(window).scrollTop() >= stickyTop) {
                    $element.addClass('active-sticky');
                } else {
                    $element.removeClass('active-sticky');
                }

                const scroll = $(window).scrollTop();
                if (scroll > position) {
                    $element.css('top', 30);
                } else {
                    $element.css('top', $(window).width() > 767 ? 256 : 150);
                }
                position = scroll;
            });

            if ($(window).width() > 767) {
                $('.dropdown-item', $elementSourceList).on(
                    'click',
                    function () {
                        $('.js-collapse-toggle', $element).attr(
                            'aria-expanded',
                            'false'
                        );
                        $('.js-collapse', $element).removeClass('show');
                    }
                );
            }
        }
    }

    return { init: _init };
})();

export default scrollSpy;
