import $ from 'jquery';
import '../vendor/select2-custom';

import SelectAdapter from 'select2/src/js/select2/data/select';
import Utils from 'select2/src/js/select2/utils';

window.$ = $;
window.jQuery = $;

const lang = document.querySelector('html').getAttribute('lang');

if (lang === 'fr-FR') require('select2/dist/js/i18n/fr');
else require('select2/dist/js/i18n/en');

function slugify(str) {
    return String(str)
        .normalize('NFKD') // split accented characters into their base characters and diacritical marks
        .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
        .trim() // trim leading or trailing whitespace
        .toLowerCase() // convert to lowercase
        .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
        .replace(/\s+/g, '-') // replace spaces with hyphens
        .replace(/-+/g, '-'); // remove consecutive hyphens
}

/**
 * Override SelectAdapter to remove special chars from items id
 */
function CustomSelectAdapter($element, options) {
    CustomSelectAdapter.__super__.constructor.call(this, $element, options);
}

Utils.Extend(CustomSelectAdapter, SelectAdapter);

CustomSelectAdapter.prototype._normalizeItem = function (item) {
    item.id = slugify(item.id);
    item = SelectAdapter.prototype._normalizeItem.call(this, item);
    return item;
};

const customSelect = (function () {
    function _init(container) {
        const selectMultiple = $(
            '.js-form-select-multiple[multiple]',
            container
        );
        // const allowRemove = true;
        const sorts = document.querySelectorAll('[name$="[sort]"]');
        sorts.forEach(sort => {
            sort.addEventListener('change', event => {
                const form = event.target.closest('form');
                if (form) {
                    form.submit();
                }
            });
        });

        // show count of selected items
        function updateSelect(select) {
            const uldiv = $(select)
                .siblings('span.select2')
                .find('ul.select2-selection__rendered');
            const count =
                uldiv.find('li').length > 0 ? uldiv.find('li').length : 0;
            let seletedText = '';
            if (select.attr('data-selected'))
                seletedText = select.attr('data-selected');
            let liText = uldiv.parent().find('.selected-text');
            let seletedOptions = '';
            const spanSearchInline = $(select)
                .siblings('span.select2')
                .find('span.select2-search--inline');
            const spanCombobox = $(select)
                .siblings('span.select2')
                .find('span.select2-selection--multiple');

            if (spanSearchInline) spanSearchInline.hide();

            setTimeout(function () {
                if (spanCombobox) spanCombobox.attr('tabindex', '0');
            }, 200);

            if (uldiv.find('li').length > 0) {
                seletedOptions = uldiv
                    .find('li')
                    .map(function () {
                        return $(this).attr('title');
                    })
                    .get()
                    .toString();
            }

            if (liText.parent().find('.selected-text').length === 0) {
                uldiv
                    .parent()
                    .append(
                        '<span class="selected-text" aria-live="polite"></span>'
                    );
                liText = uldiv.parent().find('.selected-text');
            }

            if (count === 0) {
                liText.html(
                    '<span>' +
                        select.attr('data-placeholder') +
                        '<span class="visually-hidden">0 option ' +
                        seletedText +
                        '</span>' +
                        '</span>'
                );
            } else if (count === 1) {
                liText.html(
                    '<span>' +
                        uldiv
                            .find('li.select2-selection__choice')
                            .attr('title') +
                        '<span class="visually-hidden">' +
                        seletedText +
                        '</span>' +
                        '</span>'
                );
            } else {
                liText.html(
                    '<span><span class="visually-hidden">' +
                        seletedOptions +
                        '</span>' +
                        count +
                        ' ' +
                        seletedText +
                        '</span>'
                );
            }
        }

        // init
        if (selectMultiple.length) {
            selectMultiple.each(function () {
                const $this = $(this);
                // let scrollTop;
                const dropdownParent = $(this).parent();

                const conditionSelector = this.dataset.condition;
                if (conditionSelector) {
                    const conditionInput =
                        document.querySelector(conditionSelector);

                    const options = new Map();
                    for (const optGroupEl of this.querySelectorAll(
                        'optgroup'
                    )) {
                        for (const optionEl of optGroupEl.querySelectorAll(
                            'option'
                        )) {
                            if (!options.has(optionEl.value)) {
                                options.set(optionEl.value, optionEl);
                            }
                            const option = options.get(optionEl.value);
                            const groups = (option.dataset.group || '').split(
                                '|'
                            );
                            groups.push(optGroupEl.label);
                            option.dataset.group = groups.join('|');
                        }
                    }
                    this.innerHTML = '';

                    $(conditionInput).on(
                        'select2:select select2:unselect select2:close',
                        e => {
                            disableOption(
                                [
                                    ...e.target.querySelectorAll(
                                        'option:checked'
                                    )
                                ].map(option => option.value)
                            );
                        }
                    );

                    const disableOption = groups => {
                        let hasOptions = false;
                        for (const option of options.values()) {
                            const optionGroups = (
                                option.dataset.group || ''
                            ).split('|');
                            const optionValues = option.value.split('|');
                            if (
                                groups.filter(x => optionGroups.includes(x))
                                    .length > 0
                            ) {
                                if (
                                    optionValues.length > 1 &&
                                    groups.includes(optionValues[0])
                                ) {
                                    option.text = optionValues[1];
                                    this.append(option);
                                } else if (optionValues.length === 1) {
                                    this.append(option);
                                }
                                hasOptions = true;
                            } else if (option.parentElement === this) {
                                this.removeChild(option);
                            }
                        }
                        if (hasOptions) {
                            this.removeAttribute('disabled');
                        } else {
                            $this.val(null);
                            this.setAttribute('disabled', 'disabled');
                        }
                        $this.trigger('change');
                        updateSelect($this);
                    };
                    disableOption(
                        [
                            ...conditionInput.querySelectorAll('option:checked')
                        ].map(option => option.value)
                    );
                }

                $this.select2({
                    closeOnSelect: false,
                    dropdownParent: dropdownParent,
                    dataAdapter: CustomSelectAdapter
                });

                updateSelect($(this));

                $this.on(
                    'select2:select select2:unselect select2:close',
                    function (event) {
                        selectAll($(this), event);
                        updateSelect($(this));
                    }
                );
            });
        }
    }
    function selectAll(element, event) {
        if (!event || !event.params || !event.params.originalEvent) return;
        const $select = $(element);
        const currentOption = $(event.params.originalEvent.currentTarget);
        const isAllOption = !!currentOption.attr('id').includes('all');

        if (event.type === 'select2:select') {
            if (!isAllOption) return;
            console.log('oo_-_ooo');
            const allValues = $select
                .find('option')
                .map((_, opt) => $(opt).val())
                .get();
            $select.val(allValues).trigger('change');
            $select
                .parent()
                .find('.select2-results__options')
                .find('li')
                .addClass('select2-results__option--selected');
        }

        if (event.type === 'select2:unselect') {
            if (isAllOption) {
                $select.val(null).trigger('change');
                $select
                    .parent()
                    .find('.select2-results__options')
                    .find('li')
                    .removeClass('select2-results__option--selected');
            }
            if (!isAllOption) {
                const firstLi = $select.parent().find('li[id*="all"]');
                firstLi.removeClass('select2-results__option--selected');
                $select
                    .find('option:first')
                    .prop('selected', false)
                    .trigger('change');
            }
        }
    }
    return { init: _init };
})();
export default customSelect;
